<template>
  <div class="page-content d-flex align-items-center justify-content-center">
    <div class="row w-100 mx-0 auth-page">
      <div class="col-md-4 col-xl-4 mx-auto">
        <div class="card">
          <div class="auth-form-wrapper px-4 py-5">
            <h4 class="mb-4 justify" align="center">Delete your account?</h4>
            <p>Deleting your account will permanently delete it's data. All your data can not be recovered.</p>
            <form class="forms-sample" @submit.prevent="reset_pwd">
              <div class="mt-1" align="center">
                <button type="submit" class="btn btn-danger text-white mr-2 mb-2 mb-md-0">Delete account</button>
                <a @click="(e) => { e.preventDefault(); $router.push('/auth/login'); }" href="/auth/login" class="d-block mt-3 text-muted">Cancel</a>
              </div>
            </form>
          </div>
          <div class="card-footer">
            <a href="https://wappin.id/privacy">Privacy Statement</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import authAPI from '../../../api/auth';

export default {
  name: 'ResetPassword',
  metaInfo: {
    title: 'Reset Password',
  },
  data() {
    return {
      form: {
        email: '',
      },
      alert_opt: {
        variant: 'success',
        show: false,
        message: this.$t('auth.success.init_reset_password'),
      },
    };
  },
  methods: {
    async reset_pwd() {
      const loader = this.$loading.show();
      await authAPI.unregister()
        .then(async (res) => {
          // this.alert_opt.show = true;
          if (res.error) {
            this.alert_opt.variant = 'warning';
            this.alert_opt.message = this.$t(`auth.error.init_password_${res.error.title}`);
            return;
          }
          this.$store.dispatch('auth/doLogout').then(() => {
            this.$router.push('/auth/login');
          });
          this.$message({
            type: 'success',
            message: 'Thank you for your confirmation',
          });
          // this.$router.push('/auth/login');
        })
        .catch((err) => {
          console.log(err);
          this.alert_opt = {
            show: true,
            variant: 'warning',
            message: this.$t('general.error.general'),
          };
        });
      loader.hide();
    },
  },
};
</script>
